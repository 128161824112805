import './index.scss';
import PropTypes from 'prop-types';

const LoaderAnimate = ({ type }) => {
  const isLightVersion = window.location.pathname === '/' || window.location.pathname === '/calculator' ? true : false;
  if (type === 'MODULE') {
    return (
      <div className={isLightVersion ? 'loader light' : 'loader-module'}>
        <div className="loader-module__wrapper">
          {isLightVersion ? (
            <>
              <svg width="425" height="225" viewBox="0 0 425 225" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="75" width="50" height="150" fill="#4CA4E3" />
                <path d="M50 75H200L150 125H50V75Z" fill="#4CA4E3" />
                <rect x="225" width="50" height="225" fill="#12162C" />
                <rect x="225" width="50" height="225" transform="rotate(90 225 0)" fill="#12162C" />
                <path d="M275 0H425L375 50H275V0Z" fill="#12162C" />
              </svg>
            </>
          ) : (
            <>
              <svg width="425" height="225" viewBox="0 0 425 225" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="75" width="50" height="150" fill="#4CA4E3" />
                <path d="M50 75H200L150 125H50V75Z" fill="#4CA4E3" />
                <rect x="225" width="50" height="225" fill="#ECECEC" />
                <rect x="225" width="50" height="225" transform="rotate(90 225 0)" fill="#ECECEC" />
                <path d="M275 0H425L375 50H275V0Z" fill="#ECECEC" />
              </svg>
            </>
          )}
          <div className="loader-module_lds">
            <div className="loader-module_lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={isLightVersion ? 'loader light' : 'loader'}>
      <div className="loader__wrapper">
        {isLightVersion ? (
          <>
            <svg width="425" height="225" viewBox="0 0 425 225" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="75" width="50" height="150" fill="#4CA4E3" />
              <path d="M50 75H200L150 125H50V75Z" fill="#4CA4E3" />
              <rect x="225" width="50" height="225" fill="#12162C" />
              <rect x="225" width="50" height="225" transform="rotate(90 225 0)" fill="#12162C" />
              <path d="M275 0H425L375 50H275V0Z" fill="#12162C" />
            </svg>
          </>
        ) : (
          <>
            <svg width="425" height="225" viewBox="0 0 425 225" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="75" width="50" height="150" fill="#4CA4E3" />
              <path d="M50 75H200L150 125H50V75Z" fill="#4CA4E3" />
              <rect x="225" width="50" height="225" fill="#ECECEC" />
              <rect x="225" width="50" height="225" transform="rotate(90 225 0)" fill="#ECECEC" />
              <path d="M275 0H425L375 50H275V0Z" fill="#ECECEC" />
            </svg>
          </>
        )}
        <div className="lds">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
LoaderAnimate.propTypes = {
  type: PropTypes.oneOfType([PropTypes.string]),
};
export default LoaderAnimate;
