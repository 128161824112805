import forge from 'node-forge';
import SecureLS from 'secure-ls';
var ls = new SecureLS({ encodingType: 'aes' });
import axios from 'axios';
import { API_URL } from 'shared/config';

// Функция для генерирования пары ключей приватного и публичного
const generateKeyPair = (bits) => {
  return forge.pki.rsa.generateKeyPair({ bits });
};

function base64ToBytes(base64) {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

function bytesToBase64(bytes) {
  let lengtForeach = bytes.length / 100 + 1;
  let binString = '';
  for (let i = 0; i < lengtForeach; i++) {
    binString = binString + String.fromCodePoint(...bytes.slice(100 * i, 100 * (i + 1)));
  }
  return btoa(binString);
}

// Функция для шифрования данных
const encryptData = (keyPair, originalData) => {
  let data_string = bytesToBase64(new TextEncoder().encode(JSON.stringify(originalData).toString('utf-8')));
  let lengthData = data_string.length;
  let data_encrypt = [];
  let from = 0;
  let to = 100;

  while (lengthData > 0) {
    let crypto_string = data_string.substring(from, to);
    let encryptedBufferString = keyPair.encrypt(crypto_string, 'RSAES-PKCS1-V1_5', {
      md: forge.md.sha256.create(),
    });
    data_encrypt.push(forge.util.encode64(encryptedBufferString).toString('utf-8'));
    from += 100;
    to += 100;
    lengthData -= 100;
  }
  return data_encrypt;
};

// Функция для дешифрования данных
const decryptData = (keyPair, encryptedData) => {
  let decrypto_data = [];

  for (let i = 0; i < encryptedData.length; i++) {
    let encryptedBuffer = atob(encryptedData[i]);
    let decryptedBuffer = keyPair.decrypt(encryptedBuffer, 'RSAES-PKCS1-V1_5', {
      md: forge.md.sha256.create(),
      mgf1: {
        md: forge.md.sha256.create(),
      },
    });
    decrypto_data.push(decryptedBuffer.toString('utf-8'));
  }
  return JSON.parse(new TextDecoder().decode(base64ToBytes(decrypto_data.join(''))));
};

const decryptPublicKeyPem = (keyValue) => {
  return forge.pki.publicKeyFromPem(atob(keyValue));
};

const decryptPrivateKeyPem = (keyValue) => {
  return forge.pki.privateKeyFromPem(atob(keyValue));
};

const encryptResponseData = (response) => {
  return {
    data: encryptData(decryptPublicKeyPem(ls.get('key_3')), response),
  };
};

const decryptResponseData = (response) => {
  const decryptedData = decryptData(decryptPrivateKeyPem(ls.get('key_1')), response.data);

  return decryptedData;
};

const generateAndSaveCryptograpyFrontKeys = () => {
  const keyPair = generateKeyPair(1024);

  ls.set('key_1', btoa(forge.pki.privateKeyToPem(keyPair.privateKey)).toString('utf-8'));
  ls.set('key_2', btoa(forge.pki.publicKeyToPem(keyPair.publicKey)).toString('utf-8'));

  ls.set('key_5', new Date().getTime());
};

const goldenApiInstance = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

const getAndSaveCryptograpyKeys = async () =>
  await goldenApiInstance
    .post('encryption/create_encryption_session', {
      pub_key: ls.get('key_2'),
    })
    .then((res) => {
      ls.set('key_3', res.data.content.pub_key);
      ls.set('key_4', res.data.content.api_key);
    });
const refreshAll = async () => {
  localStorage.removeItem('key_1');
  localStorage.removeItem('key_2');
  localStorage.removeItem('key_3');
  localStorage.removeItem('key_4');
  localStorage.removeItem('key_5');
  await generateAndSaveCryptograpyFrontKeys();
  await getAndSaveCryptograpyKeys();
};
export {
  generateKeyPair,
  encryptData,
  decryptData,
  decryptPublicKeyPem,
  decryptPrivateKeyPem,
  encryptResponseData,
  decryptResponseData,
  generateAndSaveCryptograpyFrontKeys,
  getAndSaveCryptograpyKeys,
  refreshAll,
};
