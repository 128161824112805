import { localStorageMethods } from 'shared/lib';

const getOrderFieldsMatchToUserId = (order) => {
  const userId = localStorageMethods.getData('userId');

  if (!userId || !order) return;

  const newOrder = {
    id: order.id,
    offerId: order.offerId,
    timeCreate: order.timeCreate,
    fiatType: order.fiatType,
    activeType: order.activeType,
    sumFiat: order.sumFiat,
    sumActive: order.sumActive,
    rate: order.rate,
    status: order.status,
    isFrozen: order.isFrozen,
    isExpired: order.isExpired,
    processStatus: order.processStatus,
    banks: order.banks,
    bankFiatTransferred: order.bankFiatTransferred,
    isAddFeedback: order.isAddFeedback,
    userRate: order.userRate,
  };

  if (userId === order.offerUserId) {
    newOrder.userId = order.orderUserId;
    newOrder.userName = order.orderUserName;
    newOrder.userDeals = order.orderUserDeals;
  } else {
    newOrder.userId = order.offerUserId;
    newOrder.userName = order.offerUserName;
    newOrder.userDeals = order.offerUserDeals;
  }

  newOrder.type = order.orderType;

  return newOrder;
};

export default getOrderFieldsMatchToUserId;
