const commit = (dataName, data) => {
  if (data === null) {
    localStorage.removeItem(dataName);
  } else {
    localStorage.setItem(dataName, JSON.stringify(data));
  }
};

const getData = (dataName) => {
  try {
    return JSON.parse(localStorage.getItem(dataName));
  } catch {
    return null;
  }
};

export { commit, getData };
