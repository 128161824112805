import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router';

import { AppContext } from './AppContext';
import { authPopupTypes } from 'shared/config';
import { localStorageMethods } from 'shared/lib';

const RequireAuth = ({ children }) => {
  const token = localStorageMethods.getData('token');

  if (!token) {
    return <Navigate to="/" state={{ popupType: authPopupTypes.login }} />;
  }

  return children;
};

export default RequireAuth;
