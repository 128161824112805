import React, { Suspense, useState } from 'react';

import './index.scss';

import { BrowserRouter } from 'react-router-dom';
import { AppContext } from './providers';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routing } from 'pages';
import LoaderAnimate from 'shared/ui/LoaderAnimate/index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  const [isHasOrders, setIsHasOrders] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);
  return (
    <>
      {isLoading && <LoaderAnimate />}
      <BrowserRouter>
        <Suspense fallback={<LoaderAnimate />}>
          <AppContext.Provider value={{ isHasOrders, setIsHasOrders }}>
            <QueryClientProvider client={queryClient}>
              <div className="app">
                <Routing />
              </div>
            </QueryClientProvider>
          </AppContext.Provider>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
