const fiatSymbol = {
  EUR: '€',
  RUB: '₽',
  USD: '$',
  CNY: '¥',
};

const getFiatSymbol = (type) => {
  if (!type) return;
  return fiatSymbol[type];
};

export default getFiatSymbol;